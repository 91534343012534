import paper from 'paper';

export enum ImageType {
  PNG = 'PNG', JPG = 'JPG', SVG = 'SVG', UNKNOWN = 'UNKNOWN'
}

export interface DrawingBaseModuleFigureInfo {
  position: number;
  totalFiguresInDocument: number;
  orientation: FigureOrientation;
  scaling: number;
  showFigureNumber: boolean;
  image: any; // byte[]
  imageType: ImageType;
  symbols: FigureSymbol[];
}

export interface DrawingBaseModuleInput {
  figureInfo: DrawingBaseModuleFigureInfo;
  config: DrawingBaseModuleConfig;
}

export enum FigureSymbolType {
  REFERENCE_SIGN_MARKER = 'referenceSignMarker',
  LINE = 'line',
  ARROW = 'arrow',
  CURVE = 'curve',
  BRACE = 'brace',
  HELP_LINE = 'helpLine'
}

export enum FigureSymbolSubType {
  REFERENCE_SIGN_TEXT = 'referenceSignText',
  REFERENCE_SIGN_UNDERLINE = 'referenceSignUnderline',
  LINE_PATH = 'linePath',
  ARROW_HEAD = 'arrowHead',
  ARROW_TAIL = 'arrowTail',
  CURVE_PATH = 'curvePath',
  BRACE_HELPLINE = 'braceHelpline'
}

export interface GuidModel {
  guid?: string;      // optional
}

export interface FigureSymbolLayer extends GuidModel {
  figureGuid: string;
  position: number;
  layerSymbols: FigureSymbol[];
  dirty: boolean;
  description?: string;
}

export interface FigureSymbol extends GuidModel {
  guid: string;      // here it's required
  figureGuid: string;
  symbolType: FigureSymbolType;
}

export interface PaletteSymbol extends FigureSymbol {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}

export interface ReferenceSignMarker extends FigureSymbol {
  guid: string;
  figureGuid: string;
  referenceSigns: ReferredReferenceSign[];
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;
  underlined: boolean;
  vertical?: HelpLineDocking;
  horizontal?: HelpLineDocking;
}

export interface HelpLineDocking {
  helpLineGuid: string;
  referenceSignMarkerGuid: string;
  alignment: Alignment;
}

export class BoundingBox {
  topLeftX: number;
  topLeftY: number;
  bottomRightX: number;
  bottomRightY: number;

  constructor(topLeftX: number, topLeftY: number, bottomRightX: number, bottomRightY: number) {
    this.topLeftX = topLeftX;
    this.topLeftY = topLeftY;
    this.bottomRightX = bottomRightX;
    this.bottomRightY = bottomRightY;
  }

  getWidth(): number {
    return this.bottomRightX - this.topLeftX;
  }

  getHeight(): number {
    return this.bottomRightY - this.topLeftY;
  }

  getMidPoint(): paper.Point {
    return new paper.Point((this.topLeftX + this.bottomRightX) / 2, (this.topLeftY + this.bottomRightY) / 2);
  }
}

export function figureSymbolIsReferenceSignMarker(figureSymbol: FigureSymbol): figureSymbol is ReferenceSignMarker {
  return !!(figureSymbol as ReferenceSignMarker).referenceSigns;
}

export interface HelpLine extends FigureSymbol {
  orientation: Orientation;
  coordinate: number;
  vertical?: HelpLineDocking;
  horizontal?: HelpLineDocking;
}

export interface ReferredReferenceSign {
  guid: string;
  name: string;
  assignedLabel: string;
}

export interface Line extends PaletteSymbol {
  dashed: boolean;
}

export interface Brace extends PaletteSymbol {
  braceType: BraceType;
}

export interface Curve extends PaletteSymbol {
  handle1x?: number,
  handle1y?: number,
  handle2x?: number,
  handle2y?: number,
  dashed: boolean;
}

export interface DrawingBaseModuleConfig {
  withSymbols?: boolean;
  withHelpLines?: boolean;
  withBackground?: boolean;
  withInfoTexts?: boolean;
  withUserHelpLines?: boolean;
}

export enum LayerName {
  BACKGROUND = 'background',
  FIGURE_INFO_TEXTS = 'figureInfoTexts',
  SYMBOLS = 'symbols',
  HELP_LINES = 'helpLines',
}

export enum FigureOrientation {
  LANDSCAPE = 'LANDSCAPE', PORTRAIT = 'PORTRAIT'
}

export enum BraceType {
  FULL = "full",
  OPEN_BOTTOM = "openBottom",
  OPEN_TOP = "openTop"
}

export enum Orientation {
  HORIZONTAL = "horizontal",
  VERTICAL = "vertical",
}

export enum Alignment {
  LEFT = "left",
  RIGHT = "right",
  TOP = "top",
  BOTTOM = "bottom",
}

export enum FigureEditorMode {
  SOLID_CURVE = "solidCurve",
  DASHED_CURVE = "dashedCurve",
  SELECTION = "selection",
  ZOOM_SELECTION = "zoomSelection"
}

export const MOUSE_ZOOM_FACTOR = 1.05;
export const BUTTON_ZOOM_FACTOR = 1.2;
export const OFFSET = 2.5;
export const RESOLUTION = 300;

export const MAX_ZOOM = 10.00;

export const SIZES = {
  A4: {
    LANDSCAPE: {
      width: 3508,
      height: 2480,
    },
    PORTRAIT: {
      width: 2480,
      height: 3508
    }
  }
}

// https://github.com/paperjs/paper.js/blob/c44f56d52fa21fd56e56a36721b45eaa18018e5d/src/text/PointText.js#L116-L119
export const BASELINE_FACTOR = 0.25;
export const ARROW_ANGLE = 155;
export const ARROW_SIZE = 30;
export const LINE_LENGTH = 260;
export const BRACE_CIRCLE_RADIUS = 30;
export const BRACE_SIZE = 250;
export const STROKE_WIDTH = 2;
export const MIN_SIZE_CURVE_HANDLE = 70;
export const TRANSPARENT_COLOR = "#FFFFFF00";
export const MIN_BRACE_LENGTH = 23;
export const DASHED_LINE = [25, 25];
export const DASHED_DOTTED_LINE = [40, 20, 10, 10, 10, 20, 40, 20, 10, 10, 10, 20];

export const DASHED_CURVE = [20, 15];

export const PASTE_OFFSET = 100;

export const STYLES = {
  TITLE: {
    fillColor: 'black',
    fontFamily: 'Arial',
    fontSize: 130, // 130px = ??pt @ 300dpi
    leading: 130,
    justification: 'center'
  },
  FIGURE_NUMBER: {
    fillColor: 'black',
    fontFamily: 'Arial',
    fontSize: 67, // 67 = ??pt @ 300dpi
    leading: 67,
    justification: 'center'
  },
  REFERENCE_SIGN: {
    fillColor: 'black',
    fontFamily: 'Arial',
    fontSize: 67, // 67 = ??pt @ 300dpi
    leading: 67,
    justification: 'center',
    selectedColor: "#eb641e"
  },
  REFERENCE_SIGN_UNDERLINE: {
    strokeColor: 'black',
  },
  LINE: {
    strokeColor: 'black',
    selectedColor: "#eb641e"
  },
  ARROW: {
    strokeColor: 'black',
    fillColor: 'black',
    selectedColor: "#eb641e"
  },
  DASHED_LINE: {
    dashArray: DASHED_LINE,
    selectedColor: "#eb641e"
  },
  CURVE: {
    strokeColor: 'black',
    selectedColor: "#eb641e"
  },
  BRACE: {
    strokeColor: 'black',
    selectedColor: "#eb641e",
  },
  BRACE_HELPLINE: {
    selectedColor: "#eb641e"
  },
  SELECTION_RECTANGLE: {
    strokeWidth: 5,
    selectedColor: "#eb641e"
  },
  HELP_LINES: {
    strokeColor: "#c9c9c9",
    strokeWidth: 5,
    dashArray: DASHED_LINE.map(x => x * 2)
  },
  USER_HELP_LINES: {
    strokeColor: "#687196",
    strokeWidth: 5,
    dashArray: DASHED_DOTTED_LINE,
    selectedColor: "#eb641e"
  }
}
export const DEFAULT_CURVE = {
  WIDTH: 400,
  HEIGHT: -200,
  HANDLE1X: -180,
  HANDLE1Y: 0.6,
  HANDLE2X: -180,
  HANDLE2Y: 2
}

export enum FigureInfoTextName {
  TITLE = 'title',
  FIGURE_NUMBER = 'figureNumber'
}

export enum SelectionMode {
  KEEP, UNSELECT, SELECT, SELECT_EXCLUSIVELY
}

/**
 * Selection config can be added to an paper.Item at data.selectionConfig
 */
export interface SelectionConfig {
  // Item should never get selected.
  neverSelect?: boolean;
  // If this path is selected, it should switch on Path.fullySelected automatically.
  fullySelectedAutomatically?: boolean;
  // Item is a custom selection marker:
  // - If selected, this 'colorForCustomSelectionMarker' color is used as strokeColor.
  // - If unselected, the strokeColor is set to transparent.
  colorForCustomSelectionMarker?: string;
  colorForCustomSelectionHelpLine?: string;
}
