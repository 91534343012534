<template>
  <div ref="aiAssistantDialog "
       class="ai-assistant-dialog"
       :style="dialogPositionAndSize">

    <div :class="'ai-assistant-dialog-child'">
      <div class="columns header-customized  mr-0">
        <div
          class=" column is-1">
        </div>
        <div class="headline column is-10"
             @mousedown="onMouseDown">
          {{ $t('aiAssistantDialog.title') }}
        </div>

        <div class="headline column is-1 close-button-customized-parent">
          <button type="button" :title="$t('aiAssistantDialog.hoverCloseButton')" class="modal-dialog-close icon-button"
                  @click="closeButtonClicked()">
            <i class="exi exi-close close-button-customized"/>
          </button>
        </div>
      </div>

      <div class="columns is-multiline is-mobileis-gapless mt-4 mb-0 padding-bottom-0 m-0">
        <div class="column is-3 is-gapless mt-0 padding-bottom-0 mb-0 has-0-bottom-padding ">
          <h6 class="title-customized"> {{ $t('aiAssistantDialog.input') }}</h6>
        </div>

        <div class="column is-6 is-gapless mt-0 padding-bottom-0 m-0 mb-0 has-small-bottom-padding">
          <select
            @change="onChangeAiModel($event.target.value)"
            :placeholder=" $t('aiAssistantDialog.model')"
            required
            size="is-small"
            class="form-control is-black-costomized-dropdown"
            :value="selectedModelIndex">
              <option value="" disabled selected hidden>{{ $t('aiAssistantDialog.model')}}</option>
              <option v-for="(llm, index) in llms" v-bind:key="index" :value="index" >{{llm.displayName}} ({{llm.aiVendorDisplayName }})</option>
          </select>

        </div>
        <div class="column is-2">
          <!-- Intentionally empty -->
        </div>
        <div class="column is-1 is-right pl-2 pb-0 mb-0 has-0-bottom-padding ai-template-doropdown-parent">
          <b-dropdown aria-role="list"
                      position="is-bottom-left">
            <template #trigger="{}">
              <button class="icon-button add-button"
                      :title="$t('aiAssistantDialog.hoverAiTemplateButton')">

                <i class="exi exi-plus"/>
              </button>
            </template>

            <b-dropdown-item class="ai-template-dropdown" aria-role="listitem" v-for="(aiTemplate, index) in filteredAiTemplates"
                             v-bind:key="index"
                             :value="index" @click="onChangeAiTemplate(index)">
              <div>
                {{ aiTemplate.aiTemplateName }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="column is-12 has-blue-selection is-gapless m-0 pt-0 pb-0">
          <textarea v-if="showPlaceholderSubstituted === false"
                    v-model="inputText"
                    class="is-black-costomized-textarea has-blue-selection"
                    :style="{width: '100%' , height: textAreaHeight}"
                    type="textarea"
                    spellcheck="false"
                    :placeholder="$t('aiAssistantDialog.inputPlaceholder')"
                    ref="aiTemplateTextArea"
          />
          <textarea v-else
                    class="is-black-costomized-textarea has-blue-selection is-disabled"
                    :value="substitutionText"
                    :style="{width: '100%' , height: textAreaHeight}"
                    type="textarea"
                    spellcheck="false"
                    :placeholder="$t('aiAssistantDialog.inputPlaceholder')"
                    :disabled="true"
                    ref="aiTemplateTextAreaDisabled"
          />
        </div>

        <div class="column is-7 is-centered has-text-centered mb-0 is-paddingless">
          <!-- Intentionally empty -->
        </div>
        <div class="column is-4 is-centered has-text-right mb-0 is-paddingless pr-2">
          {{ $t('aiAssistantDialog.substitutePlaceholders') }}
        </div>
        <div class="column is-1 is-centered has-text-centered mb-0 is-paddingless has-0-bottom-padding toggle-button-customized">
          <b-switch v-model="showPlaceholderSubstituted">

          </b-switch>
        </div>

        <div class="column is-12 is-centered has-text-centered is-paddingless m-0 p-0">
          <div class="column is-12 m-0 p-0">
            <b-button :loading="showSpinner"
                      class="button is-normal is-centered is-black-costomized "
                      @click="sendLlmRequest"
                      type="b-button"
                      :disabled="isSendButtonDisabled">
              {{ $t('aiAssistantDialog.sendButon.send') }}
            </b-button>
          </div>
        </div>

        <div class="column mb-0 pb-0 mt-0 pt-0">
          <div class="columns">
            <div class="column is-7 pb-0 is-gapless pl-0 pr-0">
              <h6 class="column title-customized pb-0 pr-0"> {{ $t('aiAssistantDialog.output') }}</h6>
            </div>
            <div class="column is-3 pl-0 pr-0">
              <h6 class="column rating-text-customized pl-0"> {{ $t('aiAssistantDialog.rateAnswer') }}</h6>
            </div>

            <div class="column is-2 pb-0 pr-0 pl-0 is-gapless is-flex-grow-0">
              <b-rate ref="ratingRef" class="column rating-customized pb-0 pl-0 is-gapless is-flex-grow-0"
                      :disabled="getIsResultPresent"
                      icon-pack="fas" @change="updateRating($event)" :key="forceRatingReset" v-model="rating"
              ></b-rate>
            </div>

          </div>
        </div>
        <div class="column is-12 mt-0 pt-0">
          <div ref="outputTextarea" tabindex="0" class="output-textarea" @keydown="onCtrlA($event)"
               v-html="llmFixedResult">
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script lang="ts">
import {Component, Prop, Ref, toNative, Vue, Watch} from 'vue-facing-decorator';
import {Editor} from '@tiptap/vue-3';
import LlmModule from '@/store/modules/LlmModule';
import {LlmRequest} from '@/api/models/LlmRequest.model';
import {Llm} from '@/api/models/Llm.model';
import {CompletionResult} from '@/api/models/CompletionResult.model';
import CompletionResultModule from '@/store/modules/CompletionResultModule';
import EditorModule from '@/store/modules/EditorModule';
import {Rate} from '@ntohq/buefy-next';
import AuthModule from '@/store/modules/AuthModule';
import {UserData} from '@/store/models/auth.model';
import AiTemplateModule from '@/store/modules/AiTemplateModule';
import {AiTemplate} from '@/api/models/aiTemplate.model';
import {ApplicationDocument} from '@/api/models/application.model';
import ApplicationModule from '@/store/modules/ApplicationModule';
import AiAssistantModule from '@/store/modules/AiAssistantModule';
import {useDefaultErrorHandling} from '@/errorHandling';
import {LocalizedMessage} from '@/api/models/exception.model';
import {showToast} from '@/api';
import {GetLocalizedExceptionMessageFor} from '@/api/services/exceptions';

@Component({
             components: {
             }
           })
class AiAssistantDialog extends Vue {
  @Ref('ratingRef') private ratingRef!: typeof Rate;

  @Ref('aiTemplateTextArea') aiTemplateTextArea!: HTMLTextAreaElement;
  @Ref('aiTemplateTextAreaDisabled') aiTemplateTextAreaDisabled!: HTMLTextAreaElement;
  private textAreaResizeObserver: ResizeObserver | null = null;
  private widthAndHeightOfTextArea = {height: 150};

  @Ref('outputTextarea') private outputTextarea!: HTMLInputElement;

  @Prop({required: true})
  private editor!: Editor;

  @Prop({required: true})
  private dialogPostion!: {top: number; left: number};

  @Prop({required: true})
  private showing!: boolean;

  private inputText = "";

  private refreshPlaceholderPending = 0;

  // save for each block (block guid) the inputText of the last request before the placeholders are replaced
  // to display the text without replaced placeholders in the dialog
  private inputTextWithoutReplacedPlaceholders: { [key: string]: string } = {};
  private forceRatingReset= 0;
  private dragging = false;
  private startingPos = {top: 0, left: 0};
  private defaultRating = 0;
  private ready = false;
  private showPlaceholderSubstituted = false;
  private defaultDialogSize = {width: 600, height: 550 };

  mounted(): void {
    this.textAreaResizeObserver = new ResizeObserver(this.handleResize.bind(this));
    this.ready = true;
  }

  private handleResize(entries: ResizeObserverEntry[]): void {
    requestAnimationFrame(() => {
      for (const entry of entries) {
        if (entry.target === this.aiTemplateTextArea) {
          const {width, height} = this.aiTemplateTextArea.getBoundingClientRect();
          if (height !== this.widthAndHeightOfTextArea.height) {
            this.widthAndHeightOfTextArea = { height: height };
          }
        } else if (entry.target === this.aiTemplateTextAreaDisabled) {
          const {width, height} = this.aiTemplateTextAreaDisabled.getBoundingClientRect();
          if (height !== this.widthAndHeightOfTextArea.height) {
            this.widthAndHeightOfTextArea = { height: height };
          }
        }
      }
    });
  }

  beforeDestroy(): void {
    if (this.textAreaResizeObserver) {
      this.textAreaResizeObserver.disconnect();
    }
    this.ready = false;
  }

  @Watch('showing')
  @Watch('showPlaceholderSubstituted')
  listenToActiveArea(newValue: boolean): void {
    AiAssistantModule.onShowSubstitutedTextChanged(newValue)?.catch(useDefaultErrorHandling);
    if(!this.showing){
      this.textAreaResizeObserver?.disconnect();
    }
    if(!this.ready || !this.showing){
      return;
    }

    if(this.textAreaResizeObserver){
      this.textAreaResizeObserver.disconnect();
        this.$nextTick(() => {
          let elementToWatch: Element | null = null;
          if(this.aiTemplateTextArea){
            elementToWatch = this.aiTemplateTextArea;
          }
          if(this.aiTemplateTextAreaDisabled){
            elementToWatch = this.aiTemplateTextAreaDisabled;
          }

          if(elementToWatch) {
            this.textAreaResizeObserver?.observe(elementToWatch);
          }
        });
    }
  }
  get getDefaultSize(): {width: number, height: number} {
      return this.defaultDialogSize;
  }
  get substitutionText(): string {
    return this.isSubstitionInProgress ? this.$t('aiAssistantDialog.placeholderSubstitutionInProgress')
                                       : this.inputTextSubstituted;
  }

  get isSubstitionInProgress(): boolean {
    return this.isSubstitutionRequestLoading || this.isPlaceholderRequestDebouncing;
  }

  get isPlaceholderRequestDebouncing(): boolean {
    return this.refreshPlaceholderPending > 0;
  }

  get isSubstitutionRequestLoading() {
    return AiAssistantModule.isLoading;
  }

  get textAreaHeight(): string {
    if(this.widthAndHeightOfTextArea === null){
      return '100%';
    }
    return this.widthAndHeightOfTextArea.height + 'px';
  }

  // TODO: dropdown auswahl diablen (ggf. anpassen)
  // TODO: write tests
  // TODO ch: what do to if no fitting llm if the if cluase is false?
  /**
   * set the llm in the dropdown menu to one of the llms that is supported for the selected aiTemplate
   * */
  private llmOfSelectedAiTemplate(): void {
    const selectedLlmMatchesTemplate =
      (LlmModule.selectedLlm?.llmName?.toLowerCase().includes(AiTemplateModule.selectedAiTemplate?.llmName.toLowerCase()!));

    if (!selectedLlmMatchesTemplate) {
       const fittingLlms =  this.llms.filter(llm =>
                                               llm.llmName?.toLowerCase().includes(AiTemplateModule.selectedAiTemplate?.llmName.toLowerCase()!))

      let maxLength = 0;
       let index = 0;
       let indexOfMax = 0;
      for (let llm in fittingLlms) {
        if (llm.length > maxLength) {
          maxLength = llm.length
          indexOfMax = index;
        }
        index += 1;
      }
      this.onChangeAiModel(this.llms.indexOf(fittingLlms[indexOfMax]))
    }
    // toast und ausgrauen + leere selektion
    // dropdown auswahl diablen (ggf. anpassen)
  }

    private onCtrlA(event: KeyboardEvent ): void {
      if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
        event.preventDefault();
        const content = this.outputTextarea;
        const range = document.createRange();
        range.selectNodeContents(content);
        const selection = window.getSelection();
        selection!.removeAllRanges();
        selection!.addRange(range);
      }
  }

  get inputTextSubstituted(): string {
      return AiAssistantModule.textWithPlaceholdersSubstituted;
  }

  get dialogPositionAndSize(): any {
    return { top: this.dialogPostion.top  + 'px', left: this.dialogPostion.left + 'px',
      width: this.defaultDialogSize.width + 'px'};
  }

  get getIsResultPresent(): boolean{
    return CompletionResultModule.currentCompletionResult===null || CompletionResultModule.isLoading;
  }

  get selectedBlockGuid(): string | null {
    return EditorModule.selectionGuidForEditor;
  }

  get selectedSemanticType(): string | null {
    return EditorModule.semanticType;
  }

  get rating(): number {
    if(this.getCompletionResult===null) {
      return 0;
    }
    return this.getCompletionResult.rating;
  }

  get filteredAiTemplates(): AiTemplate[] {
    return AiTemplateModule.filteredAiTemplates
  }

  /**
   * watch on the blockGUID changing to refresh the data in the dialog according to the new block
   * */
  @Watch('selectedBlockGuid')
  private onSelectedBlocGuidChanged(newBlockGuid: string | null, oldBlockGuid: string | null |undefined) {
    if (this.getCompletionResult === null) {
      this.resetRating = 0;
      this.inputText = "";
      return;
    }

    this.inputText = this.inputTextWithoutReplacedPlaceholders[AiAssistantModule.lastSelectedBlock!];
    this.resetRating = this.getCompletionResult.rating;

    const idxOfUsedLlm = this.llms.findIndex((obj) => {
      return (obj.llmId === this.getCompletionResult?.requestDto.llmDto.llmId);
    });
    LlmModule.setSelectedLlm(LlmModule.llms[idxOfUsedLlm]);
  }

  @Watch('inputText', {immediate: true})
  private onInputTextChange(newText: string){
    this.inputTextWithoutReplacedPlaceholders[AiAssistantModule.lastSelectedBlock!] = this.inputText;
    AiAssistantModule.changeText(newText);
  }

  private closeButtonClicked(){
    this.$emit('close');
  }

  private onMouseDown(event: MouseEvent) {
    this.dragging = true;
    this.startingPos = {top: event.clientY, left: event.clientX};
    document.addEventListener('mousemove', this.onMouseMove);
    document.addEventListener('mouseup', this.onMouseUp);
    this.$emit('dialogDragStart', this.dragging)
  }

  private onMouseMove(event: MouseEvent) {
    if(this.dragging){
      const posDifference = {top: event.clientY - this.startingPos.top, left: event.clientX - this.startingPos.left};
      event.preventDefault(); //
      this.$emit('dialogDragged', posDifference)
    }
  }

  private onMouseUp(event: MouseEvent) {
    this.dragging = false;
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    this.$emit('dialogDragEnd')
  }

  get selectedModelIndex(): number | null {
    const idxOfUsedLlm = this.llms.findIndex((obj) => {
      return (obj.llmId === LlmModule.selectedLlm?.llmId);
    });
    return idxOfUsedLlm === -1 ? null: idxOfUsedLlm;
  }
  get llmFixedResult(): string {
    if (this.getCompletionResult === null) {
      return "";
    }
    return this.getCompletionResult.responseText;
  }

  get showSpinner(): boolean {
    return CompletionResultModule.isLoading;
  }

  get isSendButtonDisabled(): boolean {
    return (this.inputText === "") || !AuthModule.isAiAssistantEnabled || LlmModule.selectedLlm === undefined;
  }

  set resetRating(defaultRating: number) {
    this.defaultRating = defaultRating;
  }

  get llms(): Array<Llm> {
    return LlmModule.llms;
  }

  get getCompletionResult(): CompletionResult | null {
    return CompletionResultModule.currentCompletionResult;
  }

  get user(): UserData | null {
    return AuthModule.user;
  }

  async delay(timeInMillis: number): Promise<void> {
    return new Promise((resolve) => setTimeout(() => resolve(), timeInMillis));
  }

  private sendLlmRequest() {
    if(LlmModule.selectedLlm === null
      || AiAssistantModule.lastSelectedBlock === null
      || AiAssistantModule.currentApplicationDocumentUuid === null) {
      return;
    }

    const llmRequest: LlmRequest = {
      requestText: AiAssistantModule.text,
      llmDto: LlmModule.selectedLlm,
      selectedBlockGuid: AiAssistantModule.lastSelectedBlock,
      applicationDocumentGuid: AiAssistantModule.currentApplicationDocumentUuid
    };

    const showToastAndUseDefaultErrorHandling = (error: any) => {
      const message = GetLocalizedExceptionMessageFor(error as LocalizedMessage);
      showToast(message);
      useDefaultErrorHandling(error);
    }

    if(LlmModule.selectedLlm.supportsStreaming) {
      CompletionResultModule.sendLlmRequestStream(llmRequest)
        .catch(showToastAndUseDefaultErrorHandling);
    } else {
      CompletionResultModule.sendLlmRequest(llmRequest)
        .catch(showToastAndUseDefaultErrorHandling);
    }
  }

  private updateRating(rating: number) {
    if(CompletionResultModule.currentCompletionResult === null) {
      return;
    }
    const payload = {
      completionResultGuid: CompletionResultModule.currentCompletionResult.guid,
      rating: rating
    }
    CompletionResultModule.updateRating(payload).finally(() => { this.forceRatingReset+=1; });
  }

  private onChangeAiModel(index: number) {
    const llm = this.llms[index];
    LlmModule.setSelectedLlm(llm);
  }

  private onChangeAiTemplate(index: number) {
    const aiTemplate = this.filteredAiTemplates[index];
    AiTemplateModule.setSelectedAiTemplate(aiTemplate);
    this.inputText = aiTemplate.aiTemplateText;
    this.llmOfSelectedAiTemplate()
  }

  get currentApplicationDocument(): ApplicationDocument | null{
    return ApplicationModule.currentApplicationDocument;
  }

  @Watch("currentApplicationDocument")
  private getAllTemplatesForCurrentApplicationDocument(): void{
    if (ApplicationModule.currentApplicationDocument?.guid != undefined) {
      AiTemplateModule.getaiTemplates(ApplicationModule.currentApplicationDocument?.guid)
    }
  }

  @Watch('user', {immediate: true})
    private userChanged(newUser: UserData | null) {
      if(AuthModule.isAiAssistantEnabled) {
        LlmModule.getLlms().then(value => {
          if(value.length > 0){
            LlmModule.setSelectedLlm(value[0])
          }
        });
      } else {
        LlmModule.setSelectedLlm(null)
      }
    }
}
export default toNative (AiAssistantDialog)
export {AiAssistantDialog};

</script>

<style lang="scss" scoped>
@import 'src/assets/styles/colors';
@import 'src/assets/styles/constants';

.header-customized {
  margin-top: 0px;
}

.title-customized {
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.rating-customized {
  margin-bottom: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-grow: 0;
  justify-content: right;
  &.is-disabled{
    :nth-child(n) {
      color: #F3F3F3;
      cursor: not-allowed;
    }
  }
}

.rating-text-customized {
  margin-bottom: 0px;
  padding-bottom: 0px;
  text-align: right;
}

.toggle-button-customized {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.is-black-costomized {
  color: #000000;
  background-color: #FFFFFF;
}

.has-0-bottom-padding {
  padding-bottom: 0px !important;
}

.has-blue-selection {
  ::selection {
    color: #FFFFFF;
    background: #0d68ce !important;
  }
}

.has-small-bottom-padding {
  padding-bottom: 3px !important;
}

.is-black-costomized-textarea {
  ::selection {
    background: #0d68ce !important;
  }

  ::v-deep {
    ::selection {
      background: #0d68ce !important;
    }
  }

  &.is-disabled {
    color: $pengine-grey;
  }

  user-select: text !important;
  -ms-user-select: text !important;
  height: 150px;
  color: #0a0a0a;
  overflow-y: auto;
  font-size: 16px;
  resize: vertical;
  width: 100%;

  padding-top: 3px !important;
  padding-left: 3px !important;
  padding-right: 3px !important;
  padding-bottom: 3px !important;

  margin-top: 3px !important;
  margin-left: 3px !important;
  margin-right: 3px !important;
  margin-bottom: 3px !important;

  border: 1px solid #4f4f4f !important;

  &:not(.is-disabled):hover, &:not(.is-disabled)::selection {
    padding-top: 2px !important;
    padding-left: 2px !important;
    padding-right: 2px !important;
    padding-bottom: 2px !important;

    margin-top: 3px !important;
    margin-left: 3px !important;
    margin-right: 3px !important;
    margin-bottom: 3px !important;
  }
}

.output-textarea {
  border: 1px solid #4f4f4f !important;
  padding: 5px;
  font-size: 16px;

  ::v-deep {
    h1 {
      font-size: 16px;
      font-weight: bold !important;
    }

    h3 {
      font-weight: bold !important;
    }

    strong {
      font-weight: bold !important;
    }

    p {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    li {
      padding-left: 10px;
    }

    ol {
    }

    a {
      color: #0d68ce;
      text-decoration-line: underline;
      text-decoration-color: #0d68ce;
    }
  }

  .strong {
    font-weight: normal !important;
  }

  .h3:hover {
    font-weight: bold !important;
  }

  p {
    padding-left: 20px;
    padding-top: 20px;
    text-indent: 50px;
  }

  overflow-y: auto;
  overflow-x: auto;
  resize: vertical;
  width: 100%;
  height: 150px;
}

.is-black-costomized-dropdown {
  color: #0a0a0a;
  font-size: 16px;
  height: 25px;
  width: 100%;
  padding: 2px !important;
  text-indent: 2px !important;
  border: 1px solid #4f4f4f !important;
}

.is-black-costomized-dropdown:hover {
  padding: 1px !important;
  text-indent: 2px !important;
  border: 2px solid #4f4f4f !important;
}

.close-button-customized-parent {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.close-button-customized {
  width: 12px;
  height: 12px;
  margin-right: 0px;
  padding-right: 0px;
}

.ai-template-doropdown-parent {
  display: flex;
  flex-grow: 1;
  justify-content: right;
}

.ai-template-dropdown {
  opacity: 2000;
  overflow: visible;

  ::selection {
    background-color: #4f4f4f !important;
    background: #4f4f4f !important;
  }
}

.ai-assistant-dialog {
  position: absolute;
  z-index: 1200;
  opacity: 1;
  visibility: visible;

  .ai-assistant-dialog-child {
    ::v-deep {
      overflow: auto;
    }

    resize: horizontal;
    height: 100%;
    margin-top: 5px;
    text-align: left;
    background-color: white;
    -webkit-box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.3);
    z-index: 1201;

    .headline {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      cursor: move;
    }
  }
}
</style>
