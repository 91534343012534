export default {
  "general": {
    "language_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
    "backTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum"])},
    "backToAlt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopieren"])},
    "clone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klonen"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlegen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeiten"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigen"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wird geladen…"])},
    "mandatoryFieldsMustBeFilledIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die gekennzeichneten Pflichtfelder müssen ausgefüllt sein."])},
    "cannotSaveWithoutChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde noch keine Änderung vorgenommen."])}
  },
  "sidebar": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü anzeigen"])},
    "library": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek"])},
      "createEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag anlegen"])},
      "setup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten"])}
    },
    "administration": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwalten"])}
    },
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen"])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info, Kontakt und Hilfe"])}
  },
  "worklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungen verwalten"])},
  "loadWorklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Anmeldungen"])},
  "emptyWorklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anmeldungen gefunden"])},
  "deleteApplicationDocument": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung löschen"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchten Sie die Anmeldung '", _interpolate(_list(0)), "' wirklich löschen?"])},
    "inactive": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung kann nicht gelöscht werden, da diese als nicht löschbar markiert ist"])}
    }
  },
  "coverSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckblatt"])},
  "editor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungseditor"])},
  "referenceNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktenzeichen"])},
  "clientSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichen des*der Anmelders*in"])},
  "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelder*in"])},
  "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angelegt"])},
  "second": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekunde"])},
  "seconds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sekunden"])},
  "minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minute"])},
  "minutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minuten"])},
  "hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunde"])},
  "hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stunden"])},
  "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag"])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagen"])},
  "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woche"])},
  "weeks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wochen"])},
  "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monat"])},
  "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monaten"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahren"])},
  "ago": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vor"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["angelegt"])},
  "datePreposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["am"])},
  "timePreposition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["um"])},
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "locales": {
    "de-DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])}
  },
  "applicationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art der Anmeldung"])},
  "applicationTypes": {
    "PATENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patent"])},
    "PATENT_APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Patentanmeldung"])},
    "UTILITY_MODEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebrauchsmuster"])}
  },
  "applicationVariant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variante"])},
  "applicationVariants": {
    "FULL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollanmeldung"])},
    "CORE_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kernanmeldung"])}
  },
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Art"])},
  "type2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "patentHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelder*in"])},
  "patentHolderAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelder*innen Adresse"])},
  "isDeletable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung nicht löschbar"])},
  "genericTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattungsbegriff"])},
  "genericTermPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriff"])},
  "genericTermComplementTextPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergänzender Text"])},
  "mainFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptfigur"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "descriptionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibungstext"])},
  "figure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figur"])},
  "figureEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figureneditor"])},
  "figure.abbreviated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fig."])},
  "createNewFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Figur anlegen"])},
  "uploadFigureBaseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Figur hochladen"])},
  "searchApplicationFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figuren durchsuchen"])},
  "setAsMainFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Als Hauptfigur festlegen"])},
  "currentMainFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Hauptfigur"])},
  "editFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figur im Figureneditor bearbeiten"])},
  "showFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figur in voller Größe anzeigen"])},
  "uploadFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bilddatei für Figur hochladen"])},
  "downloadFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figur herunterladen"])},
  "figureRendering.notUpToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Figur ist veraltet! Eine aktualisierte Version wird gerade erstellt."])},
  "figureRendering.error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Erstellen der Figur ist ein Fehler aufgetreten!"])},
  "orientation.landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Querformat"])},
  "orientation.portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochformat"])},
  "newFigure.landscape": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für eine optimale Bildqualität bitte die Bilddatei als Schwarz/Weiß Zeichnung im Format DIN A4 mit 2,5cm umlaufendem Rand und einer Auflösung von 300 DPI bereitstellen (entspricht einer Auflösung von 3508 Pixel × 2480 Pixel [B/H])"])},
  "newFigure.portrait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für eine optimale Bildqualität bitte die Bilddatei als Schwarz/Weiß Zeichnung im Format DIN A4 mit 2,5cm umlaufendem Rand und einer Auflösung von 300 DPI bereitstellen (entspricht einer Auflösung von 2480 Pixel × 3508 Pixel [B/H])"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "coverSheetCreate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung anlegen"])},
  "coverSheetEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung bearbeiten"])},
  "coverSheetClone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung klonen"])},
  "textEditorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text-Editor"])},
  "textBlocks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentstruktur"])},
  "figures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figuren"])},
  "referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen"])},
  "symbols": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbole"])},
  "helpLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfslinien"])},
  "noDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Beschreibung"])},
  "createReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen anlegen"])},
  "applyReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen auf den Anmeldungstext anwenden"])},
  "hideReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen ausblenden"])},
  "showReferenceSigns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen einblenden"])},
  "configureApplication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendung auf den Text konfigurieren"])},
  "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kennzeichen"])},
  "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriff"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffe"])},
  "stemForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortstamm"])},
  "searchStemForm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Such-Wortstamm"])},
  "searchStemRefresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Such-Wortstamm neu erzeugen"])},
  "searchStemFormTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wortstämme, für die das Bezugszeichen im Anmeldungstext zugeordnet werden soll."])},
  "excludeStemFormMatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exklusiv"])},
  "excludeStemFormMatchesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffe, die nicht als Wortreffer zugeordnet werden sollen."])},
  "stemFormMatches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Treffer"])},
  "stemFormMatchesTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuell dem Bezugszeichen zugeordnete Wortreffer."])},
  "deletedClaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Gelöschter Anspruch)"])},
  "APPLICATION_ABSTRACT": {
    "textTooLong": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Zusammenfassung überschreitet mit ", _interpolate(_list(0)), " Zeichen die Maximallänge von ", _interpolate(_list(1)), "."])}
  },
  "deleteReferenceSign": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen löschen"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchten Sie das Bezugszeichen '", _interpolate(_list(0)), "' wirklich löschen?"])}
  },
  "deleteApplicationFigure": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figur löschen"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchten Sie die Figur 'Fig. ", _interpolate(_list(0)), "' wirklich löschen?"])}
  },
  "ambigiousReferenceSignsHits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Bezugszeichen hat Worttreffer, die nicht eindeutig zugeordnet werden konnten."])},
  "searchIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche in Bibliothek"])},
  "searchMainTabs": {
    "APPLICATION_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungen"])},
    "LIBRARY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek"])}
  },
  "searchApplicationDocuments": {
    "MAIN_CLAIM_AND_EXPLANATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptanspruch & Erläuterung"])},
    "ALL_FIGURES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Figuren"])}
  },
  "searchSpace": {
    "TERM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffe"])},
    "TEXT_BLOCK_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattungen allgemein"])},
    "TEXT_BLOCK_B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattungen Figuren"])},
    "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])}
  },
  "searchSpaceSingular": {
    "TERM": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffsdefinition"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffsdefinition kopieren"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffsdefinition erstellen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begriffsdefinition editieren"])}
    },
    "TEXT_BLOCK_A": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung allgemein"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung allgemein kopieren"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung allgemein erstellen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung allgemein editieren"])}
    },
    "TEXT_BLOCK_B": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung Figuren"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung Figuren kopieren"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung Figuren erstellen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattung Figuren editieren"])}
    },
    "OTHER": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige"])},
      "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige kopieren"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige erstellen"])},
      "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonstige editieren"])}
    }
  },
  "searchLocale": {
    "de-DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "en-US": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])}
  },
  "toSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])},
  "deleteAllSearchTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Suchbegriffe löschen"])},
  "createEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["anlegen"])},
  "createLibraryEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothekseintrag anlegen"])},
  "keywords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagworte"])},
  "keywordsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagworte, unter denen der Bibliothekseintrag über die Suche gefunden werden soll."])},
  "newEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlagwort"])},
  "libraryEntryCreatedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bibliothekseintrag '", _interpolate(_list(0)), "' erfolgreich angelegt."])},
  "ratingUpdatedSucessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Anwort erfolgreich bewertet mit '", _interpolate(_list(0)), "' von 5 Sternen."])},
  "brickEntryCreatedSuccessfully": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bibliothekseintrag '", _interpolate(_list(0)), "' erfolgreich angelegt."])},
  "download": {
    "docx": {
      "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung als *.docx Datei herunterladen"])},
      "withFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine *.docx-Datei mit Text und Figuren"])},
      "withoutFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text und Figuren in separaten *.docx-Dateien"])}
    }
  },
  "exportErrorDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figuren nicht aktuell"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für die Anmeldung sind derzeit noch nicht alle geänderten Figuren im Hintergrund aktualisiert worden. Beim Export werden evtl. vorhandene, nicht aktualisierte Figuren durch einen Platzhalter ersetzt. Bitte prüfen Sie im exportierten Dokument die Figuren auf Vollständigkeit und wiederholen Sie bei Bedarf den Export zu einem späteren Zeitpunkt."])},
    "optionForce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])}
  },
  "canvas": {
    "noFigureMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Figur vorhanden"])}
  },
  "menubar": {
    "nodes": {
      "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inline"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paragraph"])}
    },
    "tooltip": {
      "figure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Figur ", _interpolate(_list(0))])},
      "referenceSign": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bezugszeichen ", _interpolate(_list(0))])},
      "undo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "' zurücknehmen"])},
      "undoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Aktionen anzeigen"])},
      "redo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["'", _interpolate(_list(0)), "' erneut ausführen"])},
      "redoList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erneut ausführbare Aktionen anzeigen"])},
      "localChanges": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " geändert"])},
      "localChangesUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderung"])},
      "remoteChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gespeicherte Änderung"])},
      "generateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles generieren und Bezugszeichen anwenden"])},
      "inline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absatz ignorieren"])},
      "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Ignorieren des Absatzes zurücknehmen"])},
      "unSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Textblock konnte nicht gespeichert werden. Bitte prüfen Sie Ihre Internetverbindung!\n\n Bei der nächsten Änderung im Dokument wird erneut versucht, den Textblock zu speichern.\n Bitte sichern Sie ggfs. Ihren Änderungen extern, wenn das Speichern weiterhin fehlschlägt."])}
    },
    "generate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu generieren"])},
    "autoGenerator": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " neu generieren"])},
    "generateAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles generieren"])},
    "create": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " hinzufügen"])},
    "delete": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " löschen"])},
    "pasteText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text einfügen"])},
    "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unterstreichen"])},
    "createCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezier-Linie zeichnen"])},
    "createDashedCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestrichelte Bezier-Linie zeichnen"])},
    "search": {
      "placeholder": {
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])},
        "replace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen..."])}
      },
      "find": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Anmeldungstext suchen"])}
      },
      "clear": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche bereinigen"])}
      },
      "replace": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersten gefundenen Treffer ersetzen"])}
      },
      "replaceAll": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Ersetzen"])},
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle gefundenen Treffer ersetzen"])}
      },
      "close": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchleiste ausblenden"])}
      }
    },
    "zoomIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vergrößern"])},
    "zoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkleinern"])},
    "activateZoomSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom-Rahmen aktivieren"])},
    "deactivateZoomSelection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom-Rahmen deaktivieren"])},
    "activateHelpLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfslinien einblenden"])},
    "deactivateHelpLines": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfslinien ausblenden"])},
    "fullZoomOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoomlevel auf 100% setzen"])},
    "scaleContentArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilddatei im Inhaltsbereich der Figur einpassen"])},
    "scaleFullscreen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilddatei in die Figur einpassen"])},
    "toggleFigureNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figurennummerierung im Bild anzeigen/ausblenden"])},
    "scaleImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild skalieren: "])},
    "scalingTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalierung der Bilddatei in der Figur festlegen"])},
    "figureNumberInputTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figurennummer"])}
  },
  "menububble": {
    "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fett"])},
    "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kursiv"])},
    "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstreichen"])},
    "addReferenceSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen hinzufügen"])},
    "addLibraryExtension": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothekseintrag anlegen"])},
    "createCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Bezier-Linie"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])}
  },
  "text-icon": {
    "bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["F"])},
    "italic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K"])},
    "underline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U"])}
  },
  "clientLocalizedKey": {
    "INVALID_ENTITY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "-Objekt ist nicht valide."])},
    "ENTITY_ALREADY_EXISTS": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es gibt bereits eine andere Anmeldung mit dem Aktenzeichen \"", _interpolate(_list(2)), "\", einschließlich einer möglicherweise als gelöscht markierten Anmeldung."])},
    "REFERENCE_SIGN_NOT_UNIQUE_IN_APPLICATION_DOCUMENT": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bezeichner '", _interpolate(_list(0)), "' und Name '", _interpolate(_list(1)), "' eines Bezugszeichens müssen eindeutig sein."])},
    "REFERENCE_SIGN_NAME_IS_TOO_LONG": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Der Bezeichner darf maximal ", _interpolate(_list(0)), " Zeichen lang sein."])},
    "REFERENCE_SIGN_LABEL_IS_INVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bezeichner eines Bezugszeichens darf nicht nur aus Zahlen bestehen."])},
    "REFERENCE_SIGN_NAME_IS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name eines Bezugszeichens darf nicht leer sein."])},
    "REFERENCE_SIGN_DUPLICATE_WORD_STEMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Bezugszeichen darf keine zwei identischen Wortstämme haben."])},
    "MODEL_NOT_FOUND": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Das angefragte ", _interpolate(_list(0)), "-Objekt mit der ID ", _interpolate(_list(1)), " konnte nicht gefunden werden."])},
    "DOCUMENT_EXPORT_ERROR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Beim Export des Dokuments '", _interpolate(_list(0)), "' ist ein Fehler aufgetreten."])},
    "SEARCH_SPACES_IS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Suchraum muss angegeben werden."])},
    "FIGURE_BASE_IMAGE_INVALID_FILE_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Bildformat: Nur JPG, PNG und SVG Dateien werden unterstützt."])},
    "FIGURE_BASE_IMAGE_CORRUPTED_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlegen fehlgeschlagen. Die Bilddatei ist beschädigt."])},
    "BLOCK_NOT_GENERATEABLE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der ausgewählte Bereich kann nicht generiert werden."])},
    "FIGURE_RENDERING_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Rendering der Figur ist ein Fehler aufgetreten."])},
    "PAYLOAD_TOO_LARGE_FIGURE_RENDERING_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die hochgeladene Figur überschreitet die maximale Dateigröße von 3MB."])},
    "FIGURE_RENDERING_DELETION_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Element konnte nicht aus der Figur gelöscht werden."])},
    "NETWORK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server nicht erreichbar. Eventuelle Änderungen wurden nicht gespeichert!"])},
    "UNDEFINED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Im Server ist ein Fehler aufgetreten. Eventuelle Änderungen wurden nicht gespeichert!"])},
    "INVALID_RICH_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Textrepräsentation enthält ungültige Zeichen. Der betroffene Textblock wurde nicht gespeichert!"])},
    "UNDO_STACK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Rückgängig machen ist ein Fehler aufgetreten. Die Änderung konnte nicht rückgängig gemacht werden."])},
    "REDO_STACK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Wiederherstellen ist ein Fehler aufgetreten. Die Änderung konnte nicht wiederhergestellt werden."])},
    "SPELLCHECK_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei der Rechtschreibkorrektur ist ein Fehler aufgetreten."])},
    "SPELLCHECK_FAILED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rechtschreibkorrektur-Prüfung ist fehlgeschlagen."])},
    "SPELLCHECK_WORD_ALREADY_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Rechtschreibkorrektur-Begriff ist bereits vorhanden."])},
    "SPELLCHECK_WORD_DOES_NOT_EXISTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibkorrektur-Begriff nicht gefunden."])},
    "SPELLCHECK_WORD_IS_EMPTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Rechtschreibkorrektur-Begriff darf nicht leer sein."])},
    "SPELLCHECK_WORD_IS_TOO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Rechtschreibkorrektur-Begriff ist zu lang."])},
    "SPELLCHECK_ERROR_TYPE_IS_TOO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Rechtschreibkorrektur Fehler-Typ ist zu lang."])},
    "LOCALIZED_BRICK_NOT_UNIQUE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es gibt bereits einen Bibliothekeintrag '", _interpolate(_list(2)), "' mit diesem Typ und Sprache."])},
    "TERM_DEFINITION_NOT_UNIQUE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es gibt bereits einen Bibliothekeintrag '", _interpolate(_list(1)), "' mit diesem Typ und Sprache."])},
    "DEFECT_EXPORT_IMAGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestimmte Abbildungen für den Export sind veraltet bzw. fehlerhaft."])},
    "PASTE_SYMBOLS_FROM_OTHER_APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbole aus einer anderen Anmeldung können nicht eingefügt werden"])},
    "SCALING_NOT_IN_RANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skalierung muss Wert zwischen 1 und 100 Prozent haben"])},
    "APPLICATION_DOCUMENT_DELETION_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anmeldung kann nicht gelöscht werden, da diese als nicht löschbar markiert ist"])},
    "UPDATE_TEXT_IN_NON_TEXT_BLOCK": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Es wurde versucht den Text eines Nicht-Text-Blocks mit der Id '", _interpolate(_list(1)), "' zu ändern."])},
    "AI_TEMPLATE_SYNTAX_ERROR": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Vorlage enthält einen Syntaxfehler in Zeile ", _interpolate(_list(0)), ", Spalte ", _interpolate(_list(1)), "."])},
    "TEST_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Error Toast"])},
    "AI_TEMPLATE_NAME_IS_TOO_LONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ai-Template Name ist zu lang."])},
    "UPSTREAM_SERVICE_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Fehler ist beim Aufruf eines Drittanbieter-Dienstes aufgetreten."])},
    "UPSTREAM_NOT_AUTH_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Aufruf eines Drittanbieter-Dienstes wurde aufgrund fehlender Berechtigungen abgelehnt."])},
    "UPSTREAM_RATE_LIMIT_ERROR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das festgelegte Ratenlimit wurde beim Aufruf eines Drittanbieter-Dienstes überschritten."])}
  },
  "entity": {
    "ApplicationDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungs"])},
    "Application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldungs"])},
    "GenericTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattungsbegriff"])},
    "Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelder*in"])},
    "AddressTmp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "PatentHolder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelder*in"])},
    "Block": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block"])},
    "TextBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text-Block"])},
    "GenericTermBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gattungsbegriff-Block"])},
    "ClaimBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruchs-Block"])},
    "ClaimListBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anspruchs-Liste-Block"])},
    "PrimaryClaimBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primärer Anspruchs-Block"])},
    "StructuralBlock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strukturblock"])},
    "RichText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "ReferenceSign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen"])},
    "ApplicationFigure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungsfigur"])},
    "FigureBaseImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figur-Basisbild"])},
    "Symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbol"])}
  },
  "nodes": {
    "reviewNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgrund manueller Änderungen im Block wurde dieser nicht automatisch neu generiert.\nFalls nötig, generieren Sie den Block neu oder ändern diesen manuell ab.\nDer Hinweis kann entfernt werden, indem Sie auf das angezeigte Symbol klicken."])}
  },
  "editorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "figuresEditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figureneditor"])},
  "palette": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palette"])},
  "library-management": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothek verwalten"])},
  "filterPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einträge nach Name und Schlagwort filtern..."])},
  "loadLibraryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Bibliothekseinträge"])},
  "emptyLibraryManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Bibliothekseinträge gefunden"])},
  "deleteLibraryEntry": {
    "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " löschen"])},
    "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Möchten Sie die ", _interpolate(_list(0)), " '", _interpolate(_list(1)), "' wirklich löschen?"])}
  },
  "header": {
    "userInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerprofil"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
    "toggle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere Funktionen anzeigen"])},
    "tab": {
      "compose": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verfassen"])},
        "search": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen & Ersetzen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen & Ersetzen"])}
        },
        "spellingAndGrammar": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibung & Grammatik"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibung & Grammatik"])},
          "inactive": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibprüfung"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rechtschreibprüfung ist deaktiviert"])}
          },
          "active": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechtschreibprüfung"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Rechtschreibprüfung ist aktiv"])}
          },
          "dictionary": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörterbuch bearbeiten"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörterbuch bearbeiten"])}
          },
          "resetIgnoredWords": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturvorschläge zurücksetzen"])},
            "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturvorschläge zurücksetzen"])},
            "confirm": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korrekturvorschläge zurücksetzen"])},
              "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie für die aktuelle Anmeldung alle ignorierten Rechtschreibkorrekturvorschläge zurücksetzen?"])},
              "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurücksetzen"])}
            }
          }
        },
        "coverSheet": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckblatt"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deckblatt"])}
        },
        "generateAll": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles generieren"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles generieren"])}
        },
        "autoReplace": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Ersetzen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-Ersetzen"])}
        },
        "symbols": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbole"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Symbole"])}
        }
      },
      "check": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfen"])},
        "dictionary": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörterbuch"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörterbuch"])}
        },
        "warnings": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnungen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warnungen"])}
        }
      },
      "statusAndAccess": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status & Zugriff"])},
        "approveForSubmission": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Einreichung freigeben"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Einreichung freigeben"])}
        },
        "newVersion": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Version"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Version"])}
        },
        "versions": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versionen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Versionen"])}
        },
        "access": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriff"])}
        }
      },
      "view": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansicht"])},
        "splitTextHorizontally": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text horizontal teilen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text horizontal teilen"])}
        },
        "splitTextVertically": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text vertikal teilen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text vertikal teilen"])}
        },
        "removeSplit": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilung aufheben"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teilung aufheben"])}
        },
        "showFigureEditor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figureneditor anzeigen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figureneditor anzeigen"])}
        },
        "hideFigureEditor": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figureneditor ausblenden"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Figureneditor ausblenden"])}
        },
        "resetTextZoom": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textzoom zurücksetzen"])},
          "tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Textzoom zurücksetzen"])}
        }
      },
      "help": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])}
      }
    },
    "pin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü fixieren"])},
    "unpin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menü einklappen"])}
  },
  "command": {
    "description": {
      "action": {
        "COMMAND_BLOCK_CREATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " angelegt"])},
        "COMMAND_BLOCK_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " geändert"])},
        "COMMAND_BLOCK_DELETE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " gelöscht"])},
        "COMMAND_BLOCK_GENERATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " neu generiert"])},
        "COMMAND_BLOCK_GENERATE_ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles neu generiert"])},
        "COMMAND_BLOCK_INLINE_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " auf Inline geändert"])},
        "COMMAND_BLOCK_PARAGRAPH_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " auf Paragraph geändert"])},
        "COMMAND_BLOCK_APPLY_REFERENCE_SIGNS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezugszeichen auf den Anmeldungstext angewandt"])}
      },
      "phrase": {
        "Within": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " in ", _interpolate(_list(1))])},
        "Refering": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " von ", _interpolate(_list(1))])}
      },
      "delimiter": {
        "Listing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([", "])},
        "Ellipsis": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), "..."])},
        "Join": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " und ", _interpolate(_list(1))])}
      }
    }
  },
  "licenses": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzinformationen"])},
    "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Produkt verwendet die folgenden Bibliotheken:"])},
    "load_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Lizenzen konnten nicht geladen werden."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lizenzen werden geladen..."])}
  },
  "about": {
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die <b>Nutzungsbedingungen</b> zur Patent Engine<sup>&reg;</sup> und die damit verbundenen <b>Datenschutzmaßnahmen</b> sind zwischen der DESIGN TO IP GmbH und Ihrem Unternehmen in einem Vertrag geregelt. Für weitere Informationen hierzu wenden Sie sich bitte an den verantwortlichen Mitarbeiter in Ihrem Unternehmen. "])}
    },
    "contact": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
    },
    "help": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hilfe"])},
      "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Hilfe benötigen und über die angegebene Telefonnummer keinen Kontakt mit uns aufnehmen können, senden Sie uns bitte eine Nachricht an die nachfolgende E-Mail-Adresse. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen."])}
    },
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "fax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefax"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])}
  },
  "symbolsList": {
    "curve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezier-Linie"])},
    "dashedCurve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestrichelte Bezier-Linie"])},
    "line": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linie"])},
    "dashedLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestrichelte Linie"])},
    "arrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pfeil"])},
    "dashedArrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gestrichelter Pfeil"])},
    "brace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klammer"])},
    "bottomOpenBrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klammer (rechts offen)"])},
    "topOpenBrace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klammer (links offen)"])},
    "horizontalHelpLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horizontale Hilfslinie"])},
    "verticalHelpLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertikale Hilfslinie"])}
  },
  "spellcheck": {
    "category": {
      "unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unbekannter Fehler"])}
    },
    "replacement": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzen mit:"])}
    },
    "info": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Details..."])}
    },
    "addToDictionary": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Wörterbuch hinzufügen."])}
    },
    "ignore": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in dieser Anmeldung ignorieren."])}
    }
  },
  "spellcheckDictionaryManagement": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wörterbuch bearbeiten"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte definieren Sie hier alle Wörter, die von der Rechtschreibprüfung nicht geprüft werden sollen:"])},
    "createEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag anlegen"])},
    "word": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag"])},
    "load": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade Einträge."])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einträge gefunden."])},
    "delete": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eintrag löschen"])},
      "question": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["\"", _interpolate(_list(0)), "\" aus dem Wörterbuch entfernen?"])}
    }
  },
  "copyReferenceSignMarkerDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfügen von Bezugszeichen"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In der Anmeldung sind ein oder mehrere kopierte Bezugszeichen nicht vorhanden. Sollen diese angelegt werden?"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "abort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])}
  },
  "cookies": {
    "cookieBannerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookie-Einstellungen"])},
    "cookieBannerMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verwenden Cookies auf unserer Website. Diese Cookies ermöglichen unseren Website-Besuchern verbesserte, persönlichere Funktionen. Sie können nachfolgend Ihre Datenschutzeinstellungen festlegen. Detaillierte Informationen und wie Sie Ihre Einwilligung jederzeit widerrufen können, finden Sie in unserer "])},
    "cookieBannerFill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" und im "])},
    "cookieBannerEssential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essentielle Cookies"])},
    "cookieBannerFunctional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funktionale Cookies"])},
    "cookieBannerAcceptAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle akzeptieren"])},
    "cookieBannerAcceptChoices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswahl akzeptieren"])},
    "cookieSettings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cookies verwalten"])}
  },
  "regulatoryInformation": {
    "languagePath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/de"])},
    "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "termsOfUse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsbedingungen"])},
    "sla": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SLA"])}
  },
  "aiAssistantDialog": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-Assistent"])},
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingabe:"])},
    "inputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfrage an das KI-Modell"])},
    "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte Modell auswählen"])},
    "substitutePlaceholders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platzhalter ersetzen"])},
    "sendButon": {
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
      "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort wird berechnet..."])}
    },
    "output": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort:"])},
    "outputPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier erscheint die Antwort des KI-Modells"])},
    "rateAnswer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antwort bewerten:"])},
    "buttonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI"])},
    "hoverText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KI-Assistent öffnen"])},
    "hoverAiTemplateButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anfragevorlage auswählen"])},
    "hoverCloseButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dialog schließen"])},
    "unexpectedInterpretationError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein unerwarteter Fehler ist beim Interpretieren einer KI-Vorlage aufgetreten"])},
    "placeholderSubstitutionInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte warten..."])}
  }
}