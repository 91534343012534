import {GuidModel} from '../../../shared/drawingbasemodule/src/api/models/drawingbase.model';

/**
 * Replace one element in the array with a new version.
 *
 * @param array may be undefined
 * @param test test predicate to find the element to be replaced
 * @param element new element
 */
export function updateElement<T>(array: T[] | undefined, test: ((t: T) => boolean), element: T) {
  if (!array) {
    return;
  }

  const index = array.findIndex(test);
  if (index >= 0) {
    // array is updated by splice(), not just array[index]=..., because this change needs to be observable with @Watch
    array.splice(index, 1, element);
  }
}

/**
 * Replace one element in the array with a new version. Identify the element to be replaced by guid.
 *
 * @param array may be undefined
 * @param element new element
 */
export function updateElementByGuid<T extends GuidModel>(array: T[] | undefined, element: T) {
  updateElement(array, it => it.guid === element.guid, element);
}

/**
 * Remove one element from the array.
 *
 * @param array may be undefined
 * @param test test predicate to find the element to be removed
 */
export function removeElement<T>(array: T[] | undefined, test: ((t: T) => boolean)) {
  if (!array) {
    return;
  }

  const index = array.findIndex(test);
  if (index >= 0) {
    array.splice(index, 1);
  }
}

/**
 * Remove one element from the array. Identify the element by guid.
 *
 * @param array may be undefined
 * @param guid remove element with this guid
 */
export function removeElementByGuid<T extends GuidModel>(array: T[] | undefined, guid: string) {
  removeElement(array, it => it.guid === guid);
}

/**
 * Transform the array into a map. The keyFunc should calculate a key for each element.
 *
 * @param array array to transform
 * @param keyFunc calculate key for each element; if it returns undefined the element is skipped
 * @return Map
 */
export function buildMap<T, I>(array: T[], keyFunc: (t: T) => I | undefined): Map<I, T> {
  return array
    .reduce((map, obj) => {
      const key = keyFunc(obj);
      if (key) {
        map.set(key, obj);
      }
      return map;
    }, new Map<I, T>());
}

/**
 * Transform the array into a map. The key is the guid.
 *
 * @param array array to transform
 * @return Map
 */
export function buildMapByGuid<T extends GuidModel>(array: T[]): Map<string, T> {
  return buildMap(array, (obj) => obj.guid);
}
